import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":"500","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showDialogHold),callback:function ($$v) {_vm.showDialogHold=$$v},expression:"showDialogHold"}},[_c(VCard,[_c(VCardTitle,{staticClass:"lighten-2 formDialog",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)","font-size":"17px"}},[_vm._v(" To "+_vm._s(_vm.type == 'H' ? 'hold' : 'resume')+" this project you need to inform a reason ")]),_c(VCardText,{staticStyle:{"margin-top":"20px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('label',[_vm._v("Reason")]),_c(VTextarea,{attrs:{"rows":"4","row-height":"23","auto-grow":"","outlined":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1)],1)],1),_c(VDivider),_c(VCardActions,{staticStyle:{"padding":"15px !important"}},[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c(VBtn,{staticClass:"mx-2 dialogButtonCancel",attrs:{"outlined":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":_vm.confirm}},[_vm._v(" Confirm ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }